<template>
	<div>
		<span class="row-start-center">
			<span style="font-size: 18px; margin-right: 10px">{{ bankTitle }}</span>
			<img v-if="$route.query.disburseType === 'choice'" class="img-bank" src="@/assets/choicebank.png" />
			<img v-if="$route.query.disburseType === 'dtb'" class="img-bank dtb" src="@/assets/DTB.png" />
			<add-disburse-entry-modal @onSubmitSuccess="fetchDisburseRecordList">
				<a-button slot="reference" type="primary" style="margin-left: 10px"> Add Entry </a-button>
			</add-disburse-entry-modal>
		</span>
		<a-spin :spinning="isLoading" class="row entries-container">
			<a-card
				v-for="(recordItem, recordItemIndex) in disburseRecordList"
				:key="recordItemIndex"
				:title="`Disbursement Entry ${recordItemIndex + 1}`"
				class="entry-item"
			>
				<a slot="extra">
					<a-popconfirm
						:ok-text="$t('enumerate.yes-no.1')"
						:cancel-text="$t('enumerate.yes-no.0')"
						:title="$t('page.confirm')"
						@confirm="deleteRecord(recordItem)"
					>
						<a-button type="link"> Delete </a-button>
					</a-popconfirm>
				</a>
				<a-descriptions :column="1">
					<a-descriptions-item :label="$t('table.Payee-Name')"> {{ recordItem.disburseName }} </a-descriptions-item>
					<a-descriptions-item :label="$t('table.Payee-Account-Number')"> {{ recordItem.disburseAccount }} </a-descriptions-item>
					<a-descriptions-item :label="$t('table.Payee-Bank-Institution')"> {{ getBankInfo(recordItem.payeeBankCode).name }} </a-descriptions-item>
					<a-descriptions-item :label="$t('table.Disbursed-Amount')"> {{ recordItem.disburseAmount }} </a-descriptions-item>
					<a-descriptions-item :label="$t('table.Payment-Time')"> {{ recordItem.paidTime }} </a-descriptions-item>
					<a-descriptions-item :label="$t('table.Receipt-Number')"> {{ recordItem.receiptNumber }} </a-descriptions-item>
					<a-descriptions-item :label="$t('table.Disbursement-Receipt')">
						<UploadPreviewFile class="img-wrapper" :uploadFilePath="recordItem.disburseCertificateFile" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
					<a-descriptions-item label="Creator"> {{ recordItem.creatorName }} </a-descriptions-item>
				</a-descriptions>
			</a-card>
		</a-spin>
	</div>
</template>

<script>
import { getDisburseRecord, deleteDisburseRecord } from '@/api/loan'
import AddDisburseEntryModal from './components/add-disburse-entry-modal.vue'
import { getBankInfo } from '@/utils'

export default {
	components: {
		AddDisburseEntryModal
	},
	data() {
		return {
			bankList: [],
			disburseRecordList: [],
			isLoading: false
		}
	},
	computed: {
		bankTitle() {
			let bankTitle = ''
			if (this.$route.query.disburseType === 'choice') bankTitle = 'Choice Bank'
			if (this.$route.query.disburseType === 'dtb') bankTitle = 'DTB'
			return bankTitle
		}
	},
	mounted() {
		this.fetchDisburseRecordList()
	},
	methods: {
		getBankInfo,
		async fetchDisburseRecordList() {
			try {
				this.isLoading = true
				const params = {
					loanId: this.$route.query.loanId,
					loanType: this.$route.query.loanType,
					disburseLoanType: this.$route.query.disburseLoanType
				}
				this.disburseRecordList = await getDisburseRecord(params)
			} finally {
				this.isLoading = false
			}
		},
		async deleteRecord(itemData) {
			const params = {
				id: itemData.id,
				loanId: this.$route.query.loanId,
				loanType: this.$route.query.loanType,
				disburseLoanType: this.$route.query.disburseLoanType
			}
			this.disburseRecordList = await deleteDisburseRecord(params)
			this.$message.success('Success')
		}
	}
}
</script>

<style lang="less" scoped>
.img-bank {
	height: 48px;
	border: 1px solid #e8e8e8;
	border-radius: 24px;
	padding: 0px 24px;

	&.dtb {
		background: #f5222d;
		padding: 4px 0px;
	}
}

.entries-container {
	margin-top: 40px;
	flex-wrap: wrap;

	.entry-item {
		width: 400px;
		margin-right: 20px;
		margin-bottom: 20px;

		.img-wrapper {
			& /deep/ img {
				max-width: 350px;
			}
		}
	}
}
</style>
