<template>
	<span>
		<span @click="toggle">
			<slot name="reference" />
		</span>

		<a-modal v-if="isShow" :visible="true" title="Add Entry" width="800px" :confirmLoading="isLoading" @ok="submit" @cancel="toggle">
			<a-form-model ref="formRef" :model="formData" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
				<a-form-model-item prop="disburseName" :label="$t('table.Payee-Name-2')">
					<a-input v-model="formData.disburseName" :max-length="50" />
				</a-form-model-item>

				<a-form-model-item prop="payeeBankCode" :label="$t('table.Payee-Bank-Institution')">
					<a-select v-model="formData.payeeBankCode">
						<a-select-option v-for="item in enumerate.bankList" :key="item.code" :value="item.code">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item
					prop="disburseAccount"
					:label="formData.payeeBankCode === 'M-PESA' ? $t('table.Payee-Mobile-Number') : $t('table.Payee-Account-Number')"
				>
					<span class="row-start-center">
						<span v-if="formData.payeeBankCode === 'M-PESA'" style="margin-right: 10px">+254</span>
						<a-input v-model="formData.disburseAccount" :max-length="50" />
					</span>
				</a-form-model-item>

				<a-form-model-item prop="disburseAmount" :label="$t('table.Disbursed-Amount')">
					<a-input v-model="formData.disburseAmount" :max-length="50" />
				</a-form-model-item>

				<a-form-model-item :label="$t('table.Payment-Time')" prop="paidTime">
					<a-date-picker
						format="YYYY-MM-DD HH:mm:ss"
						showTime
						type="date"
						v-model="formData.paidTime"
						:disabled-date="
							(startValue) => {
								return startValue.valueOf() > moment().subtract(0, 'days').valueOf()
							}
						"
					/>
				</a-form-model-item>

				<a-form-model-item prop="receiptNumber" :label="$t('table.Receipt-Number')">
					<a-input v-model="formData.receiptNumber" :max-length="50" />
				</a-form-model-item>

				<a-form-model-item :label="$t('table.Disbursement-Receipt')" prop="disburseCertificateFile">
					<a-upload :file-list="formData.disburseCertificateFile || []" :remove="handleRemove" :before-upload="beforeUpload">
						<a-button> <a-icon type="upload" /> Select File </a-button>
					</a-upload>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</span>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { UploadObs } from '@/utils/UploadObs'
import { addDisburseRecord } from '@/api/loan'
import { Rule, resetFormData } from '@/utils'

export default {
	props: {
		itemData: {
			type: Object
		},
		onSubmitSuccess: {
			type: Function
		}
	},
	data() {
		return {
			isShow: false,
			formData: {
				disburseName: '',
				payeeBankCode: 'M-PESA',
				disburseAccount: '',
				disburseAmount: '',
				paidTime: '',
				receiptNumber: '',
				disburseCertificateFile: ''
			},
			isLoading: false
		}
	},
	computed: {
		...mapGetters(['enumerate']),
		rules() {
			return {
				disburseName: [Rule.requiredInput],
				disburseAccount: [Rule.requiredInput],
				disburseAmount: [
					Rule.requiredInput,
					this.formData.payeeBankCode === 'M-PESA' ? Rule.inputInteger() : Rule.inputFloat(),
					this.formData.payeeBankCode === 'M-PESA' ? Rule.inputNumberRange(10, 250000) : Rule.inputNumberRange(0, 999999.99)
				],
				paidTime: [Rule.requiredSelect],
				receiptNumber: [Rule.requiredInput],
				disburseCertificateFile: [Rule.requiredSelect]
			}
		}
	},
	methods: {
		moment,
		toggle() {
			this.isShow = !this.isShow
			if (this.isShow) {
				resetFormData(this.formData)
				this.formData.payeeBankCode = 'M-PESA'
			}
		},
		handleRemove() {
			this.formData.disburseCertificateFile = ''
		},

		beforeUpload(file) {
			this.formData.disburseCertificateFile = [file]
			return false
		},
		uploadFile(file) {
			return new Promise((resolve, reject) => {
				let times = new Date().getTime()
				let newName = times + '.' + file.name
				let newFile = new File([file], newName, { type: file.type })
				UploadObs(this.$store.state.pageState.authObj, newFile, newName, this.$route.query.loanId)
					.then(() => {
						let path = ''
						if (this.$route.query.loanId) {
							path = this.$store.state.pageState.authObj.folderName + '/' + this.$route.query.loanId + '/' + newName
						} else {
							path = this.$store.state.pageState.authObj.folderName + '/common/' + newName
						}
						resolve(path)
					})
					.catch((err) => {
						this.$error({ content: err.message })
						this.$message.error('Upload File Fail.')
						reject(err)
					})
			})
		},
		async submit() {
			try {
				await this.$refs['formRef'].validate()
				this.isLoading = true
				const disburseCertificateFile = await this.uploadFile(this.formData.disburseCertificateFile[0])
				const params = {
					...this.formData,
					disburseType: this.$route.query.disburseType?.toUpperCase(),
					loanId: this.$route.query.loanId,
					loanType: this.$route.query.loanType,
					disburseLoanType: Number(this.$route.query.disburseLoanType),
					paidTime: this.formData.paidTime.format('YYYY-MM-DD HH:mm:ss'),
					disburseCertificateFile
				}
				const res = await addDisburseRecord(params)
				this.$message.success('Success')
				this.$emit('onSubmitSuccess', res)
				this.toggle()
			} finally {
				this.isLoading = false
			}
		}
	}
}
</script>
<style lang="less" scope></style>
