import { render, staticRenderFns } from "./record-disbursement-entries.vue?vue&type=template&id=6af33a10&scoped=true"
import script from "./record-disbursement-entries.vue?vue&type=script&lang=js"
export * from "./record-disbursement-entries.vue?vue&type=script&lang=js"
import style0 from "./record-disbursement-entries.vue?vue&type=style&index=0&id=6af33a10&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af33a10",
  null
  
)

export default component.exports